export const ubicacipontexto = 'Ubicación';
export const ubicaciponurl = 'https://www.google.com/maps?q=14.5946705,-90.5647485';
export const telefonotexto = '+502 4220 7431';
export const telefonourl = 'tel: +50242207431';
export const correotexto = 'info@bryautogt.com';
export const correourl = 'mailto:info@bryautogt.com';
export const redessociales = [
    {
        'logo': 'fab fa-whatsapp',
        'url': 'https://wa.me/+50255153469',
    },
    {
        'logo': 'fab fa-whatsapp',
        'url': 'https://wa.me/+50242207431',
    },
    {
        'logo': 'fab fa-tiktok',
        'url': 'https://www.tiktok.com/@bryautogt',
    },
    {
        'logo': 'fab fa-instagram',
        'url': 'https://www.instagram.com/bryautogt/',
    },
]

export const redessociales2 = [
    {
        'logo': 'fab fa-whatsapp',
        'url': '',
    },
    {
        'logo': 'fab fa-facebook-f',
        'url': '',
    },
    {
        'logo': 'fab fa-tiktok',
        'url': '',
    },
    {
        'logo': 'fab fa-instagram',
        'url': '',
    },
]

export const urllogo = '/';
export const logo = 'Logo.png';
export const nombre = 'Nombre.png';


export const opcion1 = 'Vehículos';
export const opcion1url = '';
export const subopciones1 = [
    {
        'sub1': 'Honda',
        'url': `${process.env.PUBLIC_URL}/marca/Honda`, 
    },
    {
        'sub1': 'Mazda',
        'url': `${process.env.PUBLIC_URL}/marca/Mazda`, 
    },
    {
        'sub1': 'Kia',
        'url': `${process.env.PUBLIC_URL}/marca/Kia`, 
    },
    {
        'sub1': 'Toyota',
        'url': `${process.env.PUBLIC_URL}/marca/Toyota`, 
    },
    {
        'sub1': 'Hyundai',
        'url': `${process.env.PUBLIC_URL}/marca/Hyundai`, 
    },
    {
        'sub1': 'Scion',
        'url': `${process.env.PUBLIC_URL}/marca/Scion`, 
    },
]

export const opcion2 = 'Servicios';
export const opcion2url = `${process.env.PUBLIC_URL}/servicios`;
export const opcion3 = 'Acerca de Nosotros';
export const opcion3url = `${process.env.PUBLIC_URL}/sobrenosotros`;
export const opcion4 = 'Contáctanos';
export const opcion4url = `${process.env.PUBLIC_URL}/contacto`;
